import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBCNgsdjNNMVJFZmeCfOla8GV0NtYz9_Og",
    authDomain: "imessage-app-dedd9.firebaseapp.com",
    projectId: "imessage-app-dedd9",
    storageBucket: "imessage-app-dedd9.appspot.com",
    messagingSenderId: "458584881787",
    appId: "1:458584881787:web:cba8c6ed9c122d1e0b00de",
    measurementId: "G-4TFTZYM8XQ"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };
export default db;
